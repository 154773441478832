@import 'settings';

// GENERIC
//
@import '~inuitcss/generic/generic.box-sizing';
@import '~inuitcss/generic/generic.normalize';
@import '~inuitcss/generic/generic.reset';
@import '~inuitcss/generic/generic.shared';

// ELEMENTS
//
@import '~@dc-framework/style/_scss/03-elements/elements.page';
@import '~inuitcss/elements/elements.headings';
@import '~inuitcss/elements/elements.images';
@import '~inuitcss/elements/elements.tables';

// OBJECTS
//
// Uncomment and add to this section as necessary.
@import '~inuitcss/objects/objects.block';
@import '~inuitcss/objects/objects.box';
@import '~inuitcss/objects/objects.crop';

// Original from inuitcss:
// @import "~inuitcss/objects/objects.layout";
@import '~inuitcss/objects/objects.list-bare';
@import '~inuitcss/objects/objects.list-inline';

// Original from inuitcss:
// @import "~inuitcss/objects/objects.media";
@import '~inuitcss/objects/objects.pack';
@import '~inuitcss/objects/objects.ratio';
@import '~inuitcss/objects/objects.table';
// Original from inuitcss:

@import '~inuitcss/objects/objects.wrapper';

@import '~@dc-framework/style/_scss/04-objects/objects.flex';
@import '~@dc-framework/style/_scss/04-objects/objects.layout';
@import '~@dc-framework/style/_scss/04-objects/objects.media';
@import '~@dc-framework/style/_scss/04-objects/objects.row';
// @import "04-objects/objects.wrapper";

// UTILITIES
//
@import '~inuitcss/utilities/utilities.clearfix';
@import '~@dc-framework/style/_scss/06-utilities/utilities.colors';

@import '~inuitcss/utilities/utilities.headings';
@import '~inuitcss/utilities/utilities.hide';
@import '~inuitcss/utilities/utilities.print';
@import '~inuitcss/utilities/utilities.spacings';
@import '~inuitcss/utilities/utilities.responsive-spacings';
@import '~inuitcss/utilities/utilities.widths';

.c-list--groups .c-list__topics {
  /* width: 68%; */
  width: 100% !important;
}

.c-list--groups .c-list__item {
  flex-wrap: wrap;
  padding: 16px 0;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-list--groups .c-list__group-name {
  // margin-bottom: 16px;
  margin-bottom: 0;
  align-self: center !important;
  max-width: none !important;
}

.c-category__title {
  margin-bottom: 8px;
  display: flex;
  // align-self: stretch;
}

.o-list-bare.c-list__categories {
  display: flex;
  flex-wrap: wrap;
}

.o-list-bare.c-list__categories.c-list__subcategories {
  padding-left: 8px;
  margin-top: 0;
  padding-right: 8px;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding-top: 8px;
}
.o-list-bare.c-list__categories.c-list__subcategories
  > .o-list-bare__item.c-list__item {
  width: 100%;
  padding: 8px;
  margin: 0;
  box-shadow: none;
}

.o-list-bare.c-list__categories.c-list__subcategories
  > .o-list-bare__item.c-list__item
  > .c-category__title {
  margin: 0;
}
.c-category__title {
  margin-bottom: 8px;
  margin-bottom: 0;
  width: 100%;
}

.o-list-bare.c-list__categories > .o-list-bare__item.c-list__item {
  width: 100%;
  background: transparent;
  flex-direction: column;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  margin: 0.5%;
  border: 0;
  min-width: 0;

  // width: 32%;

  @include mq($from: small) {
    width: 49%;
  }

  @include mq($from: large) {
    width: 32%;
    // width: 24%;
  }

  @include mq($from: huge) {
    // width: 32%;
    width: 24%;
  }
}
.o-list-bare.c-list__categories {
  width: 100%;

  &:last-child {
    margin-top: 12px;
  }
}

.c-list__categories .c-collapsible__button {
  margin-bottom: 0;
  padding: 0 16px;
}

.c-list__categories > .c-list__item > .c-collapsible {
  position: relative;
}

.c-list__categories > .c-list__item > .c-collapsible > .c-collapsible__title {
  min-height: 36px;
  width: calc(100% - 96px);
}
.c-list__categories > .c-list__item > .c-collapsible > .c-button__group {
  position: absolute;
  right: 48px;
  top: 0;
}

.c-list__categories .c-collapsible__content {
  padding: 0;
}

.c-list__categories > .c-list__item > *:not(.c-collapsible) {
  padding-left: 16px;
  padding-right: 16px;
}

.c-list__categories.c-list__subcategories > .c-list__item > * {
  padding: 0;
}

.c-list__categories.c-list__subcategories > .c-list__item > .c-button__group {
  flex: 0 0 auto;
}

.c-list__categories.c-list__subcategories
  > .c-list__item
  > .c-button__group
  > .c-button {
  margin-bottom: 0;
}

.o-list-bare.c-list__categories.c-list__subcategories
  > .o-list-bare__item.c-list__item
  > .c-category__title {
  width: auto;
  align-self: center;
}

.o-list-bare.c-list__categories.c-list__subcategories
  > .o-list-bare__item.c-list__item {
  flex-direction: row;
  padding-right: 0;
  padding-left: 8px;
  flex-wrap: nowrap;
}

.o-list-bare.c-list__categories.c-list__subcategories {
  padding-right: 0;
  margin-top: 0;
}

.c-category--childless {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  .c-category__title {
    width: auto;
    flex: 1;
    // margin-top: 4px;
  }
  .c-button__group {
    flex: 0 0 auto;
    align-self: center;
  }
  .c-button__group > .c-button {
    margin-bottom: 0;
  }
}

// On Hover of Focus within show buttons:
.c-list__categories {
  .c-button__group .c-button {
    opacity: 1;

    @include mq($from: medium) {
      opacity: 0;
    }
  }

  .c-button__group .c-button:hover,
  .c-button__group .c-button:focus {
    opacity: 1;
  }
}

.o-list-bare__item.c-list__item:hover
  > .c-category
  > .c-button__group
  .c-button,
.o-list-bare__item.c-list__item:focus-within
  > .c-category
  > .c-button__group
  .c-button {
  opacity: 1;
}

.o-list-bare__item.c-list__item .c-button__group:hover > .c-button,
.o-list-bare__item.c-list__item .c-button__group:focus-within > .c-button {
  opacity: 1;
}

.c-list__categories > .c-list__item > .c-collapsible > .c-collapsible__title {
  width: calc(100% - 96px);
  width: 100%;
  transition: width 0.2s ease;
}

.c-list__categories > .c-list__item > .c-collapsible > .c-collapsible:hover,
.c-list__categories > .c-list__item > .c-collapsible > .c-collapsible:focus,
.c-list__categories
  > .c-list__item
  > .c-collapsible
  > .c-collapsible:focus-within {
  width: calc(100% - 96px);
}

.c-list__categories
  > .c-list__item
  > .c-collapsible
  > .c-collapsible__title:hover
  + .c-button__group
  > .c-button,
.c-list__categories
  > .c-list__item
  > .c-collapsible
  > .c-collapsible__title:focus
  + .c-button__group
  > .c-button,
.c-list__categories
  > .c-list__item
  > .c-collapsible
  > .c-collapsible__title:focus-within
  + .c-button__group
  > .c-button {
  opacity: 1;
}

//
.o-list-bare.c-list__categories.c-list__subcategories
  > .o-list-bare__item.c-list__item {
  padding-top: 0;
}
.o-list-bare.c-list__categories.c-list__subcategories {
  margin-bottom: 0;
  margin-top: 0;
}
.o-list-bare.c-list__categories > .o-list-bare__item.c-list__item {
  padding-top: 12px;
  padding-bottom: 8px;

  &:hover,
  &:focus-within {
    background: white;
  }
}

.c-category--childless span {
  border-bottom: 1px solid transparent;
  display: inline-flex;
  // background-color: #ccc7;
  min-height: 34px;
  align-items: center;
}

.c-list__subcategories
  .o-list-bare__item.c-list__item:hover
  > .c-category.c-category--childless
  span {
  border-bottom: 1px dashed #ccc;
  border-top: 0;
}

.c-list__categories > .c-list__item > .c-collapsible > .c-button__group {
  top: 1px;

  .c-button.c-button--small {
    margin-bottom: 0;
  }
}

.c-list__categories.c-list__subcategories > .c-list__item > .c-draggable__item {
  margin-left: -12px;
  min-height: 34px;
}

.c-list__categories.c-list__subcategories
  > .c-list__item
  > .c-draggable__item
  .c-category__title {
  width: 100%;
}

.c-list__categories.c-list__subcategories
  > .c-list__item
  > .c-draggable__item
  .c-button__group {
  display: none;
}

.o-list-bare.c-list__categories.c-list__subcategories
  > .o-list-bare__item.c-list__item {
  padding-left: 24px;
}

/* Category Tabs */

.react-tabs__tab-panel {
  // Topics
  .c-list--groups .c-list__item .c-list__group-name:not(:last-child) {
    margin-bottom: 16px;
    max-width: none;
  }

  .c-list--groups .c-list__group-name {
    align-self: flex-start !important;
  }

  .c-list--groups .c-list__item {
    flex-direction: column;
  }

  .c-list--groups .c-input--checkbox .c-input__label {
    line-height: 1.7 !important;
  }

  .c-list__categories {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    // margin-top: 16px;

    .c-input--checkbox {
      display: flex;
    }
  }

  .c-category > .c-category {
    margin-left: 24px;
  }

  .c-category .c-category + .c-category {
    margin-top: 8px;
  }

  .c-list__categories > .c-category {
    flex: 0 1 99%;
    box-shadow: 0 0 3px 0px lightgray;
    margin: 0.5%;
    padding: 12px;
  }

  .c-category:hover {
    background: white;
  }

  @include mq($from: medium) {
    .c-list__categories > .c-category {
      flex: 0 1 49%;
    }
  }

  @include mq($from: large) {
    .c-list__categories > .c-category {
      flex: 0 1 32%;
    }
  }

  @include mq($from: huge) {
    .c-list__categories > .c-category {
      flex: 0 1 24%;
    }
  }
}
