@import '../../_scss/_settings.scss';

.c-link {
  align-self: center;
  min-height: 100%;
  // border-bottom: 2px solid transparent;
  transition: all 0.1s ease-out;
  color: $color-primary;
  text-decoration: none;

  .c-card__body .c-card__title > &:hover,
  .c-card__body .c-card__title > &:focus,
  .c-card__body .c-card__title > &:active {
    // border-bottom: 2px solid $color-primary-dark;
    color: $color-primary-dark;
    // text-decoration: underline;
  }

  @include mq($until: medium) {
    &:not(.c-link--images),
    &:focus:not(.c-link--images) {
      align-self: stretch;
    }
  }

  &.c-button--small {
    min-height: 34px;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-primary;
    text-decoration: underline;
  }
}

.c-link--images {
  &:hover,
  &:focus,
  &:active {
    .c-card__thumbnail {
      opacity: 0.65;
    }
    .c-image--placeholder {
      opacity: 0.15;
    }
  }

  &:focus {
    // border: 1px dotted $color-primary-dark;
    transform: scale(0.975);
    box-shadow: 0 0 2px 2px rgba(60, 132, 240, 0.7);
    .c-card__thumbnail {
      opacity: 0.75;
    }
    .c-image--placeholder {
      opacity: 0.15;
    }
  }
}

.c-link--file-upload {
  display: flex;
  align-items: center;
  padding: $spacing-small $spacing-medium $spacing-small $spacing-small;
  text-decoration: none;
  color: $color-primary-dark;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  flex: 1 0 0%;

  .c-icon {
    margin-right: $spacing-small;
    flex: 0 0 auto;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    background-color: $color-neutral-xlight;
  }
}

.c-link__breadcrumb {
  padding: $spacing-small $spacing-medium;
  color: $color-primary;
  padding: 4px 12px;
  text-transform: uppercase;
  border: 1px solid #3c84f0;
  text-decoration: none;
  @include font-size(12px, 1.2);

  &:hover,
  &:focus {
    color: $color-neutral-xxlight;
    background: $color-primary;
    box-shadow: none;
    outline: 0;
  }
}
