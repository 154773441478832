@import '../../_scss/_settings.scss';
// @import "@dc-framework/style/_scss/05-components/_components.content-block.scss";

.c-file-upload {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-medium;

  > * {
    width: 100%;
  }

  input[type='file'] {
    width: 100%;
  }
}

.c-file-upload-manager {
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 0;

  .c-list--file-upload-manager {
    max-height: calc(100vh - 319px);
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .c-file-upload__dropzone {
    position: relative;
    // box-shadow: 0 7px 16px -13px black;
    box-shadow: 0 5px 16px -15px black;
    margin-bottom: 2px;
    z-index: 1;
    // height: auto;
    // flex: 0 1 auto;
  }
}

.c-content-block__header-article {
  > .c-file-upload {
    .c-list--file-upload {
      position: relative;
      padding: 0;
      width: 341px;
      height: 200px;

      .c-list__item {
        min-width: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 340px;
      }
    }

    .c-file-upload__gallery {
      .c-file-upload__dropzone {
        position: absolute !important;
        left: 1px;
        right: 1px;
        // height: 199px;
        height: auto;
        min-height: auto;
        top: 1px;
        bottom: 1px;
        // width: 339px;
        width: auto;
        min-width: auto;
        // background: rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.1s ease-in;
        opacity: 0.1;

        svg,
        .c-button {
          transition: all 0.1s ease-in;
          // opacity: 0.6;
          display: inline;
        }

        &:hover,
        &:focus {
          left: 12px;
          right: 12px;
          top: 12px;
          bottom: 12px;
          background: rgba(255, 255, 255, 0.8);
          opacity: 1;

          // svg,
          // .c-button {
          //   opacity: 1;
          // }
        }
      }
    }
  }
}

.c-file-upload__children-wrapper {
  + * {
    margin-bottom: $spacing-medium;
    margin-top: 0;
  }
}

.c-file-upload__filename {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 64px);
  flex: 1 0 auto;
}

.c-file-upload__dropzone {
  cursor: pointer;
  display: flex;
  flex: 1;
  // margin-bottom: $spacing-medium;

  @include font-size(14px);

  .c-file-upload--disabled & {
    cursor: not-allowed !important;
  }

  .c-content-block__body & {
    margin-bottom: 0;
  }
}

.c-file-upload--state {
  position: relative;
  min-height: 92px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: 1px dashed $color-neutral;
  color: $color-neutral;
  padding: $spacing-medium;
  transition: color 0.2s ease-in-out;

  span {
    margin-top: 8px;
    margin-bottom: 4px;
  }

  &:not(&.c-file-upload--uploading),
  > *:not(:last-child) {
    display: flex;
    margin: 0 0 $spacing-small 0;
  }

  .c-file-upload--disabled & {
    &,
    &:hover,
    &:focus,
    &:focus-within,
    .has-focus & {
      background-color: $color-neutral-xlight;
      color: $color-neutral;

      .c-icon--secondary {
        use {
          fill: $color-neutral;
        }
      }
    }
  }

  &:hover,
  &:focus,
  &:focus-within,
  .has-focus & {
    color: $color-neutral-xdark;

    .c-icon--secondary {
      use {
        fill: $color-neutral-xdark;
      }
    }
  }

  &:focus-within,
  &:focus,
  .has-focus & {
    box-shadow: 0 0 2px 2px rgba($color-primary, 0.7);
  }

  &.c-file-upload--rejected {
    outline-color: $color-error;
    color: $color-error;

    .c-icon--secondary {
      use {
        fill: $color-error;
      }
    }
  }

  &.c-file-upload--uploading {
    outline-color: $color-primary;
    color: $color-primary;

    .c-icon--secondary {
      use {
        fill: $color-primary;
      }
    }

    > * {
      z-index: 1;
    }

    .c-file-upload--upload-status {
      content: '';
      position: absolute;
      background: $color-primary-light;
      z-index: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      max-height: 0;
      transition: max-height 0.2s;
    }
  }

  &.c-file-upload--dragging {
    outline-color: $color-success;
    color: $color-success;

    .c-icon--secondary {
      use {
        fill: $color-success;
      }
    }
  }
}

// FileUpload Gallery:
.c-file-upload__gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: $spacing-medium;
  margin-bottom: $spacing-medium;

  .c-file-upload__dropzone {
    // min-height: 141px;
    min-height: 200px;
    padding-bottom: 1px;
  }
}

.c-file-upload__gallery-link {
  display: block;
  // height: 140px;
  height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border: 0 solid $color-neutral-xlight;
  outline: 0 dashed $color-neutral;
  transition: border 0.2s ease-in-out, outline 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border-width: 3px;
    outline-width: 1px;
  }

  .c-content-block__dragger + & {
    margin-left: 0;
  }
}

.c-file-upload__gallery-image {
  object-fit: cover;
  position: absolute;
  max-width: none;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Only One Image > Thumbnail:
.c-file-upload__gallery-thumb {
  padding: 1px;
  position: relative;
  width: 260px;
}
.c-file-upload__dropzone-thumb {
  opacity: 0.1;
  position: absolute;
  width: 250px;
  background: rgba(255, 255, 255, 0.8);
  min-height: 190px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
