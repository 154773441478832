@import '../../_scss/_settings.scss';
@import '~@dc-framework/style/_scss/05-components/components.nav.scss';

.c-nav-subnav {
  > .c-nav__link:first-of-type {
    &:after {
      background-image: url('../../images/svgstore/down.svg');
    }
  }
}
