@import "../../lib/_scss/_settings.scss";

// :root {
//   font-size: calc(1rem + 0.5vw);
//   font-family: sans-serif;
//   margin: 1rem;
// }

// :focus {
//   box-shadow: 0 0 0 4px DodgerBlue;
//   outline: none;
// }

// div:not([tabindex]):focus {
//   box-shadow: none;
// }

// * {
//   box-sizing: border-box;
// }

// body {
//   overflow-x: hidden;
// }

.table-container {
  overflow-x: auto;
  max-height: calc(100vh - 325px);

  .table-container--wide {
    position: fixed;
    z-index: 1000;
    left: 32px;
    right: 32px;
    width: calc(100vw - 64px);
    height: 100%;
    overflow: auto;
    bottom: 0;
    top: 28vh;
    height: 71vh;

    .c-table {
      margin-bottom: 0;
    }
  }

  @include mq($until: small) {
    max-height: none;

    .c-table-cell--header {
      &:nth-child(2) {
        position: relative;
      }
    }
    .c-table-cell {
      &:nth-child(2) {
        position: relative;
      }
    }
  }
}

.c-table {
  min-width: 1200px;
  border-collapse: separate;
  @include font-size(14px);
}

.c-table-cell {
  padding: 16px 8px;

  &:nth-child(2) {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 10;

    &.u-bgcolor-secondary-xlight {
      background-color: $color-secondary-xlight;
    }
  }

  span[id] {
    margin-top: -85px;
    padding-bottom: 85px;
    display: block;
    visibility: hidden;
  }
}

.c-table-cell--header {
  position: sticky;
  top: 0;
  z-index: 11;
  border-bottom: 1px solid gray;

  &:not(.u-bgcolor-secondary-xlight) {
    background-color: $color-neutral-xxlight;
  }

  &:not(:last-child) {
    border-right: 1px dashed lightgray;
  }

  &:nth-child(1n + 2) {
    width: 25ch;
  }

  &:nth-child(1n + 5) {
    width: 10ch;
  }

  &:first-child,
  &:nth-last-child(2),
  &:last-child {
    width: 5ch;
  }

  &:nth-child(2) {
    position: sticky;
    left: 0;
    z-index: 12;
  }

  .c-button--small {
    width: 100%;
    justify-content: space-between;
    padding: 0;
    text-transform: none;
    text-align: left;
  }
}

.c-table__body {
  vertical-align: top;

  .c-table-cell {
    &:first-child,
    &:nth-child(1n + 5) {
      text-align: center;
    }
  }
}

.c-table--stripped {
  .c-table__row {
    // display: flex;
    // align-items: center;
    // margin-bottom: 0;
    // padding-top: $spacing-small;
    // padding-bottom: $spacing-small;
    background-color: $color-neutral-xxlight;

    &:nth-child(odd) {
      background-color: shade($color-neutral-xlight, 1%);
      &:focus-within {
        background-color: $color-primary-xlight;
      }
    }

    &:hover,
    &:focus-within {
      background-color: $color-primary-xlight;
    }
  }

  .c-table__header .c-table__row {
    background-color: $color-neutral-xxlight;

    &:hover,
    &:focus-within {
      background-color: $color-neutral-xxlight;
    }
  }
}
// th,
// td {
//   border: 2px solid #000;
//   padding: 0.75rem;
//   text-align: left;
// }

// th {
//   font-weight: bold;
//   white-space: nowrap;
// }

// th {
//   background: #000;
//   color: #fff;
// }

// tr:first-of-type th:not(:last-child) {
//   border-right-color: #fff;
// }

// tr:first-child th:first-child,
// tr:not(:first-child):not(:last-child) th {
//   border-bottom-color: #fff !important;
// }

// Responsive?
// .lists-container {
//   display: none;
// }

// @media (max-width: 400px) {
//   .lists-container {
//     display: block;
//   }
// }

// dl {
//   display: flex;
//   flex-wrap: wrap;
// }

// dl > * {
//   flex: 0 0 50%;
//   margin: 0;
// }

// dt {
//   padding-right: 0.5rem;
// }

// dt {
//   font-weight: bold;
// }

// th button {
//   background: #fff;
//   color: #000;
//   border: 0;
//   padding: 0.125rem 0.5rem;
//   border-radius: 0.25rem;
//   font-weight: bold;
//   vertical-align: middle;
//   margin-left: 0.333rem;
// }

// h3 {
//   background: #000;
//   color: #fff;
//   padding: 0.5rem;
// }
.c-table-cell--wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  svg {
    flex: 0 0 30px;
    stroke: currentColor;
    stroke-width: 20;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    width: 0.5rem;
    height: 1.5em;
  }
}

// .visually-hidden {
//   position: absolute !important;
//   clip: rect(1px, 1px, 1px, 1px) !important;
//   padding: 0 !important;
//   border: 0 !important;
//   height: 1px !important;
//   width: 1px !important;
//   overflow: hidden !important;
// }
