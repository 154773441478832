@import "../../_scss/_settings.scss";
@import "@dc-framework/style/_scss/05-components/_components.input.checkbox-radiobutton.scss";

.c-input-checkbox--uploaded-image {
  margin-bottom: 0;
  height: 200px;
  display: flex;

  .c-input__label {
    width: 100%;
    z-index: 0;
  }

  .c-file-upload__gallery-image {
    z-index: -1;
    transition: padding 0.1s ease-in-out;

    // line-height: 200px;
    text-align: center;
    font-family: $font-family-regular;
    font-style: normal;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c-input__field-radiobutton,
  .c-input__field-checkbox {
    & + .c-input__label::before {
      border-radius: 4px;
      margin-right: 0;
      margin-left: $spacing-medium;
      margin-top: $spacing-medium;
      background-color: $color-neutral-xxlight;
    }

    &:checked + .c-input__label::after {
      top: $spacing-medium;
      left: $spacing-medium;
    }

    &:checked,
    &:hover,
    &:focus {
      & + .c-input__label {
        .c-file-upload__gallery-image {
          padding: $spacing-tiny;
        }
      }
    }

    &:focus + .c-input__label {
      .c-file-upload__gallery-image {
        outline: none;
        border-radius: $radius-small;
        box-shadow: 0 0 2px 2px rgba($color-primary, 0.7);
      }
    }
  }
}
