@import "../../_scss/_settings.scss";
@import "@dc-framework/style/_scss/05-components/_components.cards.scss";

.c-card--horizontal {
  .c-card__header {
    align-self: stretch;
    min-height: 0;
    max-height: none;
    height: 190px;

    .c-link {
      display: flex;
      width: 100%;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .c-card__actions {
    @include mq($until: small) {
      padding: $spacing-medium;

      .c-card__metadata {
        margin-left: $spacing-small;
        flex: 1 1 60%;
        max-width: 60%;
      }
      .c-button__group {
        flex: 1 1 40%;
      }
      .c-card__date {
        max-width: 100%;
      }
    }
  }

  .c-card__content {
    flex-direction: column;
  }

  .c-card__description {
    flex: 1 0 auto;
  }

  .c-collapsible {
    width: 100%;
    max-width: calc(100% - 120px);

    .c-breadcrumbs__wrapper {
      margin-top: 0;
    }
    .c-breadcrumbs__wrapper-title {
      @include font-size(14px);
    }
  }
  .c-collapsible__content:not([hidden]) {
    margin: 0;
  }
}

.c-card__body {
  .c-card__title > .c-link {
    text-decoration: none;
    color: $color-neutral-dark;
  }
}

.c-card__thumbnail {
  opacity: 1;
  transition: opacity 0.1s ease-out;
}
