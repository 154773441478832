@import '../../_scss/_settings.scss';
@import '@dc-framework/style/_scss/05-components/_components.input.scss';

.c-input__error-msg {
  .c-input--error + & {
    display: block;
  }
}

.c-input-ckeditor {
  cursor: text;
  user-select: text;
}

.c-input--small {
  margin-bottom: 0;

  .c-input__field {
    padding: 4px 12px;
    min-height: 0;
  }
}

.c-input--category,
.c-input--sub-category {
  .c-input__field {
    margin-left: -12px;
  }
}

.c-input--sub-category {
  .c-input__field {
    width: calc(100% + 24px);
  }
}

.c-input--inline {
  display: flex;
  align-items: center;

  .c-input__label {
    margin-bottom: 0;
    flex: 1 0 auto;
    min-width: 170px;
  }

  .c-input__wrapper,
  .c-input-select__field {
    flex: 1 0 calc(100% - 170px);
  }
}
