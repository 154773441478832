@import "./lib/_scss/settings";

@import "./lib/_scss/main";

// @import '@dc-framework/style/_scss/index.scss';

body {
  background-image: url("/assets/images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: $color-neutral-xlight;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(30, 50, 40, 0.8);
    z-index: -1;
  }
}
