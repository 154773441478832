@import "../../_scss/settings";
.p-tabs {
  background-color: inherit;

  .react-tabs {
    background-color: inherit;
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid $color-neutral;
    display: flex;
    margin: 0;

    // position: sticky;
    // top: 95px;
    background-color: inherit;
    z-index: 10;
  }

  .react-tabs__tab {
    position: relative;
    display: inline-flex;
    align-items: center;

    list-style: none;
    user-select: none;
    cursor: pointer;

    padding: $spacing-medium 0 ($spacing-tiny * 3);
    margin: 0;

    text-decoration: none;
    font-family: $font-family-regular;
    color: $color-neutral;
    text-transform: capitalize;

    &:not(:first-child) {
      margin-left: $spacing-large;
    }

    @include inuit-font-size(18px, 1);
  }

  .react-tabs__tab--selected {
    color: $color-primary-dark;
    // font-family: $font-family-bold;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 3px;
      background: $color-primary-dark;
    }
  }

  .react-tabs__tab-panel {
    display: none;
    padding: $spacing-large 0;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  .react-tabs__tab--disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.p-tabs--no-padding {
  padding: 0;
}
