@import "../../_scss/_settings.scss";

.c-breadcrumbs__wrapper {
  margin-top: $spacing-large;
}

.c-breadcrumbs__wrapper-title {
  font-family: $font-family-light;
  text-transform: uppercase;
  color: $color-neutral;
  margin-bottom: $spacing-small;
  @include font-size(12px);
}

.c-breadcrumb {
  & + & {
    margin-top: $spacing-small;
  }

  .c-breadcrumb__item {
    position: relative;
    border: 1px solid $color-primary;
    border-right: 1px solid transparent;
    border-radius: 0;

    text-transform: none;
    text-decoration: none;
    font-family: $font-family-regular;

    padding: $spacing-small $spacing-medium;

    background-color: inherit;
    // background-color: $color-neutral-xxlight;
    z-index: 2;
    color: $color-primary;

    display: inline-flex;
    align-items: center;

    @include font-size(14px, 1.2);

    @include font-size(12px, 1.2);
    padding: 4px 12px;
    text-transform: uppercase;

    & + & {
      margin-left: -2px;
    }

    &::after {
      content: "";
      position: absolute;
      width: $spacing-medium + $spacing-small;
      height: $spacing-medium + $spacing-small;
      border: 1px solid $color-primary;
      transform: rotate(45deg);
      border-bottom: 0;
      border-left: 0;
      right: -($spacing-small + $spacing-tiny + 1);
      // background: $color-neutral-xxlight;
      background: inherit;
      z-index: -1;

      width: $spacing-medium;
      height: $spacing-medium;
      right: -9px;
    }

    &:not(:first-child) {
      padding-left: $spacing-large;
      padding-right: $spacing-medium;
      // background: $color-neutral-xxlight;
      background: inherit;
      border-left: 1px solid transparent;

      padding-left: 24px;
      padding-right: $spacing-medium;

      &::before {
        content: "";
        position: absolute;
        width: $spacing-medium + $spacing-small;
        height: $spacing-medium + $spacing-small;
        border: 1px solid $color-primary;
        transform: rotate(45deg);
        border-bottom: 0;
        border-left: 0;
        left: -($spacing-small + $spacing-tiny);
        // background: $color-neutral-xxlight;
        background: inherit;
        z-index: -1;

        width: $spacing-medium;
        height: $spacing-medium;
        left: -9px;
      }
    }

    &:active {
      color: $color-primary;
    }

    &:hover,
    &:focus {
      color: $color-neutral-xxlight;
      background: $color-primary;
      box-shadow: none;
      z-index: 3;
      outline: 0;

      &::after {
        background-color: $color-primary;
      }
      &::before {
        background-color: $color-neutral-xxlight;
      }

      & + .c-breadcrumb__item {
        z-index: 2;
      }
    }

    &:not(:hover):not(:focus) {
      z-index: 1;
    }
  }

  @include mq($until: medium) {
    // padding: $spacing-medium;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .c-breadcrumb__item {
      display: inline-block;
      border: 1px solid $color-primary;
      padding: $spacing-tiny ($spacing-tiny + $spacing-small);
      margin: $spacing-tiny 0;

      &:first-child {
        &::after {
          content: "";
          position: absolute;
          transform: none;
          right: auto;
          left: $spacing-small;
          top: 100%;
          bottom: auto;
          border: 0;
          border-bottom: 1px solid $color-primary;
          border-left: 1px solid $color-primary;
          height: 100%;
          width: $spacing-medium;
        }
      }

      &:hover,
      &:focus {
        &::after,
        &::before {
          background-color: transparent !important;
        }
      }

      &:nth-child(1n + 2) {
        // color: red;
        margin-left: $spacing-medium + $spacing-small;
        border-left: 1px solid $color-primary;
        padding: $spacing-tiny ($spacing-tiny + $spacing-small);

        &::after,
        &::before {
          content: none;
        }

        &::after {
          content: "";
          position: absolute;
          transform: none;
          right: auto;
          left: $spacing-small;
          top: 100%;
          bottom: auto;
          border: 0;
          border-bottom: 1px solid $color-primary;
          border-left: 1px solid $color-primary;
          height: 100%;
          width: $spacing-medium;
        }
      }

      &:nth-child(1n + 3) {
        margin-left: $spacing-large + $spacing-medium;
      }

      &:nth-child(1n + 4) {
        margin-left: $spacing-large + $spacing-large + $spacing-small;
      }

      &:last-child {
        &::after,
        &::before {
          content: none;
        }
      }
    }
  }
}

.c-breadcrumb--in-table {
  // padding: $spacing-medium;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .c-breadcrumb__item {
    display: inline-block;
    border: 1px solid $color-primary;
    padding: $spacing-tiny ($spacing-tiny + $spacing-small);
    margin: $spacing-tiny 0;

    &:first-child {
      &::after {
        content: "";
        position: absolute;
        transform: none;
        right: auto;
        left: $spacing-small;
        top: 100%;
        bottom: auto;
        border: 0;
        border-bottom: 1px solid $color-primary;
        border-left: 1px solid $color-primary;
        height: 100%;
        width: $spacing-medium;
      }
    }

    &:hover,
    &:focus {
      &::after,
      &::before {
        background-color: transparent !important;
      }
    }

    &:nth-child(1n + 2) {
      // color: red;
      margin-left: $spacing-medium + $spacing-small;
      border-left: 1px solid $color-primary;
      padding: $spacing-tiny ($spacing-tiny + $spacing-small);

      &::after,
      &::before {
        content: none;
      }

      &::after {
        content: "";
        position: absolute;
        transform: none;
        right: auto;
        left: $spacing-small;
        top: 100%;
        bottom: auto;
        border: 0;
        border-bottom: 1px solid $color-primary;
        border-left: 1px solid $color-primary;
        height: 100%;
        width: $spacing-medium;
      }
    }

    &:nth-child(1n + 3) {
      margin-left: $spacing-large + $spacing-medium;
    }

    &:nth-child(1n + 4) {
      margin-left: $spacing-large + $spacing-large + $spacing-small;
    }

    &:last-child {
      &::after,
      &::before {
        content: none;
      }
    }
  }
}
