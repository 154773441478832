@import "../../_scss/_settings.scss";

.c-draggable__item {
  display: flex;
  width: 100%;
  transform: translate3d(0px, 0px, 0px);

  &:not(.c-content-block--draggable) {
    height: 100%;
    display: flex;
  }
}
