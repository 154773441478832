@import "../../_scss/_settings.scss";

.c-tag {
  // @TODO: convert in button?
  background-color: $color-neutral-light;
  border-radius: 2px;
  display: inline-flex;
  margin: 2px 4px 2px 0;
  min-width: 0;
  box-sizing: border-box;
  padding: 6px;
  color: $color-neutral-xdark;
  user-select: none;

  @include font-size(14px, 1);
}

.p-input-select-container {
  .p-input-select__multi-value {
    flex: 0 0 auto;
    margin: $spacing-tiny;
  }

  .p-input-select__control {
    min-height: $min-height-form-item;
    border-radius: $radius-small;
    border-color: $color-neutral;

    .c-input--error & {
      color: $color-error;
      border-color: $color-error;
    }
  }

  .p-input-select__control--is-focused {
    outline: none;
    box-shadow: 0 0 2px 2px rgba($color-primary, 0.7);
  }

  .p-input-select__value-container {
    padding: 0 $spacing-small;
    margin: 0;
  }

  .p-input-select__placeholder + .css-1g6gooi,
  .css-1g6gooi {
    margin: $spacing-tiny;
    padding: 0;
  }

  .p-input-select__multi-value__label {
    padding: (($spacing-tiny / 2) + $spacing-tiny);
    @include inuit-font-size(16px, 1.5);
  }

  .p-input-select__multi-value__remove {
    padding-left: $spacing-tiny;
    padding-right: $spacing-tiny;
    min-height: 36px;
  }

  .p-input-select__indicators {
    padding: (($spacing-tiny / 2) + $spacing-tiny) 0;
    align-self: flex-start;
    margin-top: 1px;
  }

  .css-1alnv5e:hover {
    cursor: pointer;
    background: inherit;
  }
}
