@import "../../_scss/_settings.scss";
@import "@dc-framework/style/_scss/05-components/_components.content-block.scss";

.c-content-block__header-article {
  & ~ .o-list-bare.o-layout {
    padding-top: $spacing-large;
    margin-top: $spacing-medium;
    border-top: 1px dashed $color-neutral;
  }
}

.c-content-block:not(.c-card--horizontal) {
  .c-card__actions {
    z-index: 1;

    @include mq($until: medium) {
      width: auto;
      position: absolute;
      box-shadow: none;
      background: none;
    }
  }
}
