@import '../../_scss/_settings.scss';

.c-list--stripped {
  .c-list__item {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;

    &:nth-child(odd) {
      background-color: shade($color-neutral-xlight, 1%);
      &:focus-within {
        background-color: $color-primary-xlight;
      }
    }

    &:hover,
    &:focus-within {
      background-color: $color-primary-xlight;
    }
  }

  &.c-list--stripped-2-col {
    @include mq($from: medium) {
      .c-list__item {
        &:nth-child(odd) {
          background-color: $color-neutral-xxlight;
        }

        &:nth-child(4n + 1),
        &:nth-child(4n + 4) {
          background-color: shade($color-neutral-xlight, 1%);
        }
      }
    }
  }
}

.c-list__item {
  margin-bottom: $spacing-large;

  &:last-child {
    margin-bottom: 0;

    @include mq($until: medium) {
      margin-bottom: $spacing-large;
    }
  }

  @include mq($from: medium) {
    margin-bottom: $spacing-small;
  }

  @include mq($from: small, $until: medium) {
    min-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-list--file-upload {
  margin-bottom: $spacing-medium;

  max-height: 500px;
  overflow: auto;
  padding: $spacing-medium;

  .c-list__item {
    position: relative;
    margin-bottom: 0;
    padding: 0;
    outline: 1px dotted $color-neutral-light;
    outline-offset: -1px;
  }

  .c-button--action-alert {
    position: absolute;
    right: $spacing-small;
    top: 50%;
    transform: translateY(-50%);
  }
}

// @ToDo: fileupload image, extended dropzone!
// .c-list--file-upload-images {
//   position: relative;
//   margin-left: 8px;

//   .c-list__item{
//     &.c-list__item-empty{
//       min-height: 200px;
//       background: transparent;
//       outline: none;
//     }
//   }

//   .c-file-upload__dropzone{
//     position: absolute;
//     width: calc(100% + 16px);
//     height: calc(100% + 16px);
//     z-index: 0;
//     top: -8px;
//     left: -8px;
//   }

//   .c-button--small-upload{
//     margin-bottom: 64px !important;
//   }
// }

.c-list--file-upload-manager {
  .c-list__item {
    display: flex;
    padding: 0;
    align-items: center;
    background-color: $color-neutral-xxlight;
    margin: $spacing-tiny 0;
    width: 100%;

    * {
      margin-bottom: 0;
    }

    .c-input--checkbox {
      margin-top: 4px;
      padding: 16px;
    }

    .c-input__label {
      width: 100%;

      .c-icon {
        flex: 1 0 32px;
        // margin-top: -3px;
        // margin-right: 4px;
        margin-top: -8px;
        margin-right: 0;
        order: 1;
      }
    }

    .c-button__group {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    .c-link--file-upload {
      padding: 20px 16px 16px 56px;

      .c-icon {
        margin-left: -42px;
        flex: 0 0 auto;
        position: absolute;
      }
    }

    @include mq($from: medium) {
      margin: $spacing-tiny;
      width: calc(50% - #{$spacing-small});

      .c-content-block__body & {
        margin: $spacing-tiny 0;
        width: 100%;
      }
    }
  }

  .c-button--action-primary,
  .c-button--action-alert {
    position: relative;
    right: $spacing-small;
    top: auto;
    transform: none;
  }

  @include mq($from: medium) {
    display: flex;
    flex-wrap: wrap;

    .c-content-block__body & {
      padding: 0;
    }
  }
}

.c-file-upload-manager {
  .c-list--file-upload-manager {
    .c-list__item {
      .c-button__group {
        @include mq($until: large) {
          transform: none;
          top: $spacing-medium;
        }
      }

      .c-link--file-upload {
        padding: 20px 96px 16px 56px;

        @include mq($until: large) {
          align-items: flex-start;
        }
      }

      .c-button--action-primary,
      .c-button--action-alert {
        right: 0;
      }
    }
  }
}

.c-list--draggable {
  .c-button--dragger {
    & ~ * {
      margin-left: $spacing-large + $spacing-tiny -2;
    }
  }
}

.c-list--groups {
  padding: 0;

  .c-list__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 16px;
  }
  > .c-list__item:nth-child(odd) {
    background-color: $color-neutral-xlight;

    &:focus-within {
      background-color: $color-primary-xlight;
    }
  }

  > .c-list__item:nth-child(even) {
    border: 1px solid $color-neutral-xlight;
  }

  > .c-list__item:hover,
  > .c-list__item:focus-within {
    background-color: tint($color-primary-xlight, 55%);
  }

  .c-list__group-name {
    align-self: flex-start;
    // color: #3c84f0;
    color: tint($color-neutral-dark, 10%);
    padding: 4px 0;
    text-transform: uppercase;
    text-decoration: none;
    max-width: 30%;

    @include font-size(14px, 1);
  }

  .c-list__topics {
    width: 68%;
    display: inline-flex;
    flex-wrap: wrap;
  }

  .c-input--checkbox {
    margin-bottom: 16px;

    .c-input__label {
      color: $color-neutral-dark;
      @include font-size(16px, 1);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq($from: medium) {
    .c-list__item {
      margin-bottom: $spacing-small;
    }
  }

  @include mq($until: small) {
    .c-list__item {
      flex-wrap: wrap;
      margin-bottom: 0;
      // margin-bottom: $spacing-small;
    }

    .c-list__group-name {
      max-width: 100%;
      width: 100%;
    }

    .c-list__topics {
      width: 100%;
      margin-left: 32px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }
}
