@import "../../_scss/_settings.scss";

.c-logo {
  height: 85px;
  width: 115px;
  padding: 0 $spacing-tiny;
  display: inline-block;
  margin-right: $spacing-small;
  // margin-left: -$spacing-small;
  // border-radius: $radius-small;
  // border: 1px solid $color-primary-dark;
  display: inline-flex;
  align-items: center;
  // img {
  //   object-fit: cover;
  // }
  flex: 0 0 115px;
}
