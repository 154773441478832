$font-family-regular: "Noto", Arial, Helvetica, sans-serif !default;
$font-family-bold: "NotoBold", Arial, Helvetica, sans-serif !default;
$font-family-italic: "NotoItalic", Arial, Helvetica, sans-serif !default;
// $font-family-light: 'LatoLight', Arial, Helvetica, sans-serif !default;
// $inuit-wrapper-width: 1600px;
// $inuit-wrapper-width: huge;

$inuit-config: (
  debug: false
);

// $inuit-fractions: 1 2 3 4 5 6 7 8 9;

@import "~@dc-framework/style/_scss/00-settings/settings.config";
@import "~@dc-framework/style/_scss/00-settings/settings.core";
@import "~@dc-framework/style/_scss/00-settings/settings.colors";
@import "~@dc-framework/style/_scss/00-settings/settings.global";

$inuit-wrapper-width: 1600px;
$inuit-row-breakpoint: small;
$inuit-offsets: true;

// TOOLS
//
@import "~@dc-framework/style/_scss/01-tools/tools.aliases";
@import "~@dc-framework/style/_scss/01-tools/tools.svg-background";
@import "~inuitcss/tools/tools.font-size";
@import "~inuitcss/tools/tools.hidden";
@import "~inuitcss/tools/tools.clearfix";
@import "~sass-mq/mq";
