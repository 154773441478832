@import "../../_scss/_settings.scss";
@import "@dc-framework/style/_scss/05-components/_components.collapsible.scss";

.c-collapsible {
  width: 100%;
}

.c-collapsible__button {
  padding: 0;
  margin-bottom: $spacing-medium;
  justify-content: flex-start;

  *:first-child {
    margin-bottom: 0;
  }

  .c-breadcrumbs__wrapper-title {
    margin: 0;
  }

  .c-icon {
    margin-left: $spacing-small;
    use {
      fill: $color-neutral;
    }
  }
}

.c-collapsible__content {
  padding: 0 $spacing-medium 0 0;

  &:not([hidden]) {
    margin: auto;
  }

  > .c-breadcrumb:first-child {
    margin-top: $spacing-small;
  }
}

.c-collapsible.is-active {
  outline: 2px dashed $color-neutral;
  outline-offset: 6px;
}
