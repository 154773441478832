@import "../../_scss/_settings.scss";

// @TODO: How to use this value only in storybook?
code {
  display: inline-block;
  padding: 0 8px;
  border: dashed 0.5px black;
  margin: 4px auto;
  color: brown;
}

.c-page {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
  min-height: 100vh;
}

.c-page--full-height {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100vh;
}

.c-page--centered {
  align-items: center;
  justify-content: center;
}

.c-page__header {
  // position: relative;
  // background-color: #fff;
  width: 100%;
  padding: $spacing-small $spacing-large;
  background-color: $color-neutral-xxlight;
  z-index: 1;
  // height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  box-shadow: 0px 4px 4px -5px rgba(7, 22, 73, 0.3);

  > *:not(:last-child) {
    margin-right: $spacing-medium;
  }

  @include mq($until: small) {
    & {
      // padding: $spacing-small $spacing-medium;

      > *:not(:last-child) {
        margin-right: 0;
      }

      .c-logo {
        margin: auto;
      }

      .c-nav__list {
        justify-content: center;
      }
    }
  }
}

.c-page__header--fixed {
  position: fixed;
  height: 105px;
  margin-left: -72px;
  right: 0;
  z-index: 20;

  display: flex;
  // flex-direction: column;
  // justify-content: center;
  align-items: center;
  // border: 1px solid $color-neutral;

  @include mq($from: small) {
    // width: calc(100% - 72px);
  }

  & + .c-page__content {
    margin-top: 105px;
  }
}

.c-page__header-actions {
  display: flex;
  justify-content: flex-end;
  // flex: 1 0 auto;
}

.c-page__title {
  flex: 1 1 auto;
  font-family: $font-family-bold;
  margin-bottom: 0;

  @include inuit-font-size(24px);

  @include mq($until: small) {
    order: 3;
    margin: $spacing-medium 0;
    text-align: center;
  }
}

.c-page__title--primary-dark {
  color: $color-primary-dark;
}

.c-page__title-subtitle {
  @include inuit-font-size(16px);
  font-family: $font-family-regular;
  margin-bottom: 0;
}

.c-page__content {
  position: relative;
  width: 100%;
  padding: 0 $spacing-large;
  background-color: $color-neutral-xxlight;

  flex: 1 1 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  @include mq($until: small) {
    padding: $spacing-small $spacing-medium;
  }

  @include mq($until: medium) {
    max-width: 100%;
  }
}

.c-page__footer {
  width: 100%;
  padding: 0 $spacing-large;
  background-color: $color-neutral-xxlight;
}

.c-page__wrapper-text {
  max-width: 36rem;
  margin-bottom: $spacing-large;
}
