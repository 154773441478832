@import "../../_scss/_settings.scss";
@import "@dc-framework/style/_scss/05-components/_components.notification.scss";

.c-notification--views {
  display: inline-flex;
  align-items: center;
  padding: 4px 4px 4px 8px;
  border: 1px solid $color-primary-light;
  border-radius: 4px;
  background: rgba($color-primary, 0.03);

  .c-icon {
    width: 36px;
    height: 36px;
    border: 1px solid $color-primary-light;
    padding: 8px;
    border-radius: 50%;
    background: white;
  }

  .c-notification__label {
    text-align: center;
    span {
      color: shade($color-neutral, 20%);
      display: block;
      padding: 0 8px;
      @include font-size(10px, 1.2);
    }

    .c-notification__main-label {
      font-family: $font-family-light;
      color: $color-primary;
      @include font-size(14px, 1);
    }
  }

  .c-card--horizontal & {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 10;

    @include mq($until: medium) {
      bottom: auto;
      top: 8px;
      background: white;
      z-index: 11;
      right: 8px;
    }
  }
}
