@import "../../_scss/settings";

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.5s ease 0.1s;

  .c-card--panel {
    transform: translateX(100%);
    transition: transform 0.1s ease 0.5s;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;

  .c-card--panel {
    transform: translateX(0);
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  .c-card--panel {
    transform: translateX(100%);
  }
}

.c-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 20;
  background-color: rgba($color-neutral-xdark, 0.5);
}

.c-modal__content {
  position: absolute;
  border: 1px solid #ccc;
  background: #fff;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.ReactModal__Content {
  margin: $spacing-huge auto;
}

.c-modal__close-button {
  position: absolute;
  right: $spacing-small + $spacing-tiny;
  top: $spacing-medium + $spacing-tiny;
  opacity: 0.4;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
  }
}
