@import "../../_scss/settings";

.c-notification-pill {
  position: fixed;
  top: -$spacing-large;
  left: 50%;
  margin-right: $spacing-tiny;
  padding: $spacing-tiny $spacing-medium;

  border-radius: $radius-medium;
  border: 1px solid transparent;

  display: inline-flex;
  align-items: center;

  @include inuit-font-size($inuit-global-font-size, 1.7);
  font-family: $font-family-regular;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;

  background-color: $color-neutral;
  color: $color-neutral-xlight;
  opacity: 0;

  transform: translateX(-50%);
  transition: top 0.3s ease-in-out, opacity 0.1s ease-in-out;
  z-index: 100;
}

.c-notification-pill--success {
  background-color: $color-success;
}

.c-notification-pill--error {
  background-color: $color-error;
}

.c-notification-pill--show {
  top: $spacing-large;
  opacity: 1;
}
